<template>
  <div>
    <p class="mb-4">
      Using the SciFinder Scholar for searching, find the Chemical Abstracts Registry number for the
      following compound:
    </p>

    <p class="mb-5">
      <img style="max-width: 240px" :src="imageName" />
    </p>
    <p class="mb-5">
      <calculation-input
        v-model="inputs.input1"
        prepend-text="CAS Number:"
        style="max-width: 500px"
      />
    </p>

    <p class="mb-3">
      To find the Chemical Abstracts Registry number for a compound, you can use SciFinder Scholar.
      It's a comprehensive source for searching the chemical literature. You can enter the name of
      the compound into SciFinder Scholar to retrieve its chemical formula, structure, and the
      unique CAS Registry Number that identifies it. For example, if you're looking for the CAS
      number of “sodium lauryl sulfate,” you can search for it in SciFinder Scholar, and it will
      provide the relevant information.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUTS47LitQ1',
  components: {CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value ?? 1;
    },
    imageName() {
      if (this.versionNumber === 1) {
        return '/img/assignments/UTS/compound1.png';
      } else if (this.versionNumber === 2) {
        return '/img/assignments/UTS/compound2.png';
      } else if (this.versionNumber === 3) {
        return '/img/assignments/UTS/compound3.png';
      } else if (this.versionNumber === 4) {
        return '/img/assignments/UTS/compound4.png';
      } else if (this.versionNumber === 5) {
        return '/img/assignments/UTS/compound5.png';
      } else if (this.versionNumber === 6) {
        return '/img/assignments/UTS/compound6.png';
      } else if (this.versionNumber === 7) {
        return '/img/assignments/UTS/compound7.png';
      } else if (this.versionNumber === 8) {
        return '/img/assignments/UTS/compound8.png';
      } else if (this.versionNumber === 9) {
        return '/img/assignments/UTS/compound9.png';
      } else if (this.versionNumber === 10) {
        return '/img/assignments/UTS/compound10.png';
      } else if (this.versionNumber === 11) {
        return '/img/assignments/UTS/compound11.png';
      } else if (this.versionNumber === 12) {
        return '/img/assignments/UTS/compound12.png';
      } else if (this.versionNumber === 13) {
        return '/img/assignments/UTS/compound13.png';
      } else if (this.versionNumber === 14) {
        return '/img/assignments/UTS/compound14.png';
      } else if (this.versionNumber === 15) {
        return '/img/assignments/UTS/compound15.png';
      } else if (this.versionNumber === 16) {
        return '/img/assignments/UTS/compound16.png';
      } else if (this.versionNumber === 17) {
        return '/img/assignments/UTS/compound17.png';
      } else if (this.versionNumber === 18) {
        return '/img/assignments/UTS/compound18.png';
      } else if (this.versionNumber === 19) {
        return '/img/assignments/UTS/compound19.png';
      } else if (this.versionNumber === 20) {
        return '/img/assignments/UTS/compound20.png';
      } else if (this.versionNumber === 21) {
        return '/img/assignments/UTS/compound21.png';
      } else if (this.versionNumber === 22) {
        return '/img/assignments/UTS/compound22.png';
      } else if (this.versionNumber === 23) {
        return '/img/assignments/UTS/compound23.png';
      } else if (this.versionNumber === 24) {
        return '/img/assignments/UTS/compound24.png';
      } else if (this.versionNumber === 25) {
        return '/img/assignments/UTS/compound25.png';
      } else if (this.versionNumber === 26) {
        return '/img/assignments/UTS/compound26.png';
      } else if (this.versionNumber === 27) {
        return '/img/assignments/UTS/compound27.png';
      } else if (this.versionNumber === 28) {
        return '/img/assignments/UTS/compound28.png';
      } else if (this.versionNumber === 29) {
        return '/img/assignments/UTS/compound29.png';
      } else if (this.versionNumber === 30) {
        return '/img/assignments/UTS/compound30.png';
      } else if (this.versionNumber === 31) {
        return '/img/assignments/UTS/compound31.png';
      } else {
        return 'error';
      }
    },
  },
};
</script>
<style scoped></style>
